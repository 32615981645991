interface Translations {
  [language: string]: {
    [key: string]: string;
  };
}

const translations: Translations = {
  en: {
    "topbar.home": "Home",
    "topbar.activities": "Activities",
    "topbar.team": "Team",
    "topbar.contact": "Contact",
    "topbar.news": "News",
    "activities.title": "Dominant activities",
    "activities.companylaw.title": "Corporate law",
    "activities.commerciallaw.title": "Commercial law, contracts and proceedings",
    "activities.tax.title": "Tax advice and proceedings",
    "activities.domiciliation.title": "Company domiciliation services",
    "activities.branding.title": "Trademarks and patents",
    "activities.securitization.title": "Securitization operations",
    "activities.bank.title": "Bank and Financial Services Provider",
    "activities.investment.title": "Investment companies and  funds",
    "activities.compliance.title": "Compliance, Regulation & Control",
    "activities.foundation.title": "Associations and foundations",
    "activities.litigation.title": "Litigation",
    "activities.companylaw.description": " ",
    "activities.commerciallaw.description": " ",
    "activities.tax.description": " ",
    "activities.domiciliation.description": " ",
    "activities.branding.description": " ",
    "activities.securitization.description": " ",
    "activities.bank.description": " ",
    "activities.investment.description": " ",
    "activities.compliance.description": " ",
    "activities.foundation.description": " ",
    "activities.litigation.description": " ",
    "team.dg": "Attorney-at-law",
    "team.sp": "Senior Corporate lawyer",
    "contact.avencor": "Accounting and tax advisor services Avencor S.A.",
  },
  fr: {
    "topbar.home": "Accueil",
    "topbar.activities": "Activités",
    "topbar.team": "L'équipe",
    "topbar.contact": "Contact",
    "topbar.news": "Actualités",
    "activities.title": "Activités principales",
    "activities.companylaw.title": "Droit des sociétés",
    "activities.commerciallaw.title": "Droit commercial, contrats et procédures",
    "activities.tax.title": "Fiscalité et procédures",
    "activities.domiciliation.title": "Services de domiciliation de sociétés",
    "activities.branding.title": "Marques et brevets",
    "activities.securitization.title": "Opérations de titrisation",
    "activities.bank.title": "Banques et Professionnels du Secteur Financier",
    "activities.investment.title": "Fonds d’investissement",
    "activities.compliance.title": "Compliance, Réglementation et Contrôle",
    "activities.foundation.title": "Associations et fondations",
    "activities.litigation.title": "Contentieux",
    "activities.companylaw.description": " ",
    "activities.commerciallaw.description": " ",
    "activities.tax.description": " ",
    "activities.domiciliation.description": " ",
    "activities.branding.description": " ",
    "activities.securitization.description": " ",
    "activities.bank.description": " ",
    "activities.investment.description": " ",
    "activities.compliance.description": " ",
    "activities.foundation.description": " ",
    "activities.litigation.description": " ",
    "team.dg": "Avocat à la Cour",
    "team.sp": "Conseiller Juridique sociétés",
    "contact.avencor": "Bureau Comptable et services fiscaux Avencor S.A.",
  },
  de: {
    "topbar.home": "Startseite",
    "topbar.activities": "Dienstleistungen",
    "topbar.team": "Über uns",
    "topbar.contact": "Kontakt",
    "topbar.news": "Nachrichten",
    "activities.title": "Tätigkeitsschwerpunkte",
    "activities.companylaw.title": "Gesellschaftsrecht",
    "activities.commerciallaw.title": "Handelsrecht, Handelsverträge und Handelsverfahren",
    "activities.tax.title": "Besteuerung und Steuerverfahren",
    "activities.domiciliation.title": "Domizilierung",
    "activities.branding.title": "Marken und Patente",
    "activities.securitization.title": "Verbriefungen",
    "activities.bank.title": "Banken und Finanzdienstleister",
    "activities.investment.title": "Investitmentfonds und Kapitalanlagegesellschaften",
    "activities.compliance.title": "Compliance, Regulierungen und Kontrolle",
    "activities.foundation.title": "Vereine und Stiftungen",
    "activities.litigation.title": "Gerichtliche und aussergerichtliche Verfahren",
    "activities.companylaw.description": " ",
    "activities.commerciallaw.description": " ",
    "activities.tax.description": " ",
    "activities.domiciliation.description": " ",
    "activities.branding.description": " ",
    "activities.securitization.description": " ",
    "activities.bank.description": " ",
    "activities.investment.description": " ",
    "activities.compliance.description": " ",
    "activities.foundation.description": " ",
    "activities.litigation.description": " ",
    "team.dg": "Rechtsanwalt",
    "team.sp": "Juristin Fachgebiet Gesellschaftsrecht",
    "contact.avencor": "Buchhaltungs- und Steuerdienstleistungen Avencor S.A.",
  },
};

export const activities = [
  {
    titleID: "activities.companylaw.title",
    descriptionID: "activities.companylaw.description",
  },
  {
    titleID: "activities.commerciallaw.title",
    descriptionID: "activities.commerciallaw.description",
  },
  {
    titleID: "activities.tax.title",
    descriptionID: "activities.tax.description",
  },
  {
    titleID: "activities.domiciliation.title",
    descriptionID: "activities.domiciliation.description",
  },
  {
    titleID: "activities.branding.title",
    descriptionID: "activities.branding.description",
  },
  {
    titleID: "activities.securitization.title",
    descriptionID: "activities.securitization.description",
  },
  {
    titleID: "activities.bank.title",
    descriptionID: "activities.bank.description",
  },
  {
    titleID: "activities.investment.title",
    descriptionID: "activities.investment.description",
  },
  {
    titleID: "activities.compliance.title",
    descriptionID: "activities.compliance.description",
  },
  {
    titleID: "activities.foundation.title",
    descriptionID: "activities.foundation.description",
  },
  {
    titleID: "activities.litigation.title",
    descriptionID: "activities.litigation.description",
  },
];

export default translations;
